
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.css';
import { getLanguage } from 'react-multi-lang'
import { ConfigProvider } from 'antd';
import ca from 'antd/es/locale-provider/ca_ES';
import es from 'antd/es/locale-provider/es_ES';
import en from 'antd/es/locale-provider/en_GB';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/en-gb';

//import FormSummer from './components/FormSummer';
import Formulari from './pages/Formulari';

function App() {

  moment.locale('ca');
  let language = getLanguage();
  const lang = sessionStorage.getItem('Language');
  if(lang){
    //language = lang==="ca"?ca:es;
    switch (lang) {
      case "ca":
        language=ca;
        break;
      case "es":
        language=es;
        break;
      case "en":
        language=en;
        break;
      default:
        language=es;
    }
  }

  return (
    <BrowserRouter>
      <ConfigProvider locale={language}>
        <Switch>
          <Route exact path="/">
            <Formulari/>
          </Route>
          <Route exact path="/en">
            <Formulari lang={'en'}/>
          </Route>
          <Route exact path="/es">
            <Formulari lang={'es'}/>
          </Route>
          <Route exact path="/ca">
            <Formulari lang={'ca'}/>
          </Route>
        </Switch>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
