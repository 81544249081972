import React, { useState, useEffect } from "react";
import { translate, setLanguage, getLanguage } from 'react-multi-lang';

import FormSummer from '../components/FormSummer';

import { Layout } from 'antd';
const { Header, Footer, Content } = Layout;



function Formulari(props) {

  //const [imatge, setImatge] = useState("https://s3-eu-west-1.amazonaws.com/admin.netagencias.com/britishcostadaurada.com//carousel/en/24.png");

  const imatge =require('../images/summer.png');

  useEffect(() => {
    if(props.lang){
      changeLang(props.lang);
    }else{
      const lang = sessionStorage.getItem('Language');
      if(lang){
        changeLang(lang);
      }
    }
  },[props.lang]);


    const changeLang = (lang) =>{
      setLanguage(lang);
      sessionStorage.setItem('Language',lang);
    }

  return(
    <Layout>
      <Header style={{height: '300px', backgroundColor: '#e2f0e9', textAlign: 'center'}}>
      <img alt="logo SummerCamp" src={imatge} height={300}/>

      </Header>
      <Content>
        <FormSummer />
      </Content>
      <Footer>Footer</Footer>
    </Layout>
  );

}

export default translate(Formulari)
