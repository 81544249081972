import React, { useState, useEffect } from "react";
import { translate } from 'react-multi-lang';
import Nursery from "../dades/nursery.json";

import { Row, Col, Typography, Button, Card, Checkbox, Space, Form, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

import { AllHtmlEntities } from 'html-entities';

const entities = new AllHtmlEntities();

function SummerCamp(props){

  const [producte, setProducte] = useState(Nursery);
  const [imatge, setImatge] = useState(Nursery.url_image || "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!");

  const [propietatsBotons, setPropietatsBotons] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);


  useEffect(() => {
    generaBotons();
  },[props.item]);



  const generaBotons = () => {

    const propietats_botons = producte.propietats.map((p) =>{

      const radioStyle = {
            width:'373px',
            marginTop: '8px',
          };
      //const text = entities.decode(p.text[props.t('idioma')]);
      const text = entities.decode(p.text[props.t('idioma')]);


      return(
        <Col span={24} key={p.id_propietat}>

          {p.tipus ==='titol'
            ?
            <Row justify="left">
              <span style={{fontSize: '1rem'}}><b>{text}</b></span>
            </Row>
            :
            <Row justify="space-between">
              <Row justify="left">
                <Checkbox value={p.id_propietat} style={{marginRight: '5px'}}/>
                <>{text}</>
              </Row>
              <span style={{color:'red', marginLeft: '10px'}}>{p.preu}€</span>
            </Row>
          }

        </Col>
      );
    });

  setPropietatsBotons(propietats_botons);

}

  return(
    <div style={{width: '400px'}}>
    <Row justify="center">
      <Typography.Title level={4}>{entities.decode(producte.nom[props.t('idioma')])}</Typography.Title>
    </Row>
    <Row justify="center">
      <Typography.Title level={4} style={{marginTop: 0}}>{entities.decode(producte.descripcio[props.t('idioma')])}</Typography.Title>
      <img alt="logo SummerCamp" src={imatge} width='100%' onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+IMAGE+NOT+FOUND!!")}/>
    </Row>

      <Row justify="center">
        <Checkbox.Group style={{margin: '10px', width: '100%'}}>
          <Row justify="space-between">
          {propietatsBotons}
          </Row>
        </Checkbox.Group>
      </Row>



    <>
      <Row justify="center" style={{marginTop: '10px'}}>
        <Button type="primary">
          Pagar
        </Button>
      </Row>
    </>



    </div>
  );

}
export default translate(SummerCamp);
